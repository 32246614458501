import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { baseUrl, customApi } from '../../widget/apis';
import { ContextState } from '../../widget/context/ContextMain';
import { checkisBrand } from '../../widget/layout/Nav';
import LoadingPage from '../../widget/Loading/LoadingPage';
import MapCont from '../../widget/map/MapCont';
import UploadWP from '../../widget/uploadWithProgress';
import { Select } from 'antd';
import { GetCountriesAPi } from '../../widget/apis/public.services/get.countries';
import { GetCitiesAPi } from '../../widget/apis/public.services/get.cities';
import { json } from 'node:stream/consumers';


interface ISettingsProps {

}

const Settings: React.FunctionComponent<ISettingsProps> = (props) => {
    const Ctx = useContext(ContextState);
    const formRef: any = useRef()
    const [displayPage, setDisplayPage] = useState<boolean>(false)
    const [settingData, setSettingData] = useState<any[any]>([])
    const [orderDescription, setOrderDescription] = useState<any[]>([])
    const [selectedImages, setSelectedImages] = useState<{ cover: { path: any, url: string }, logo: { path: any, url: string }, brandLogo: { path: any, url: string } }>()
    const [position, setPosition] = useState<any>([24.0937676, 53.4281644]);
    const [displayMap, setDisplayMap] = useState<boolean>(false)
    const [countriesState, setCountriesState] = useState<any[]>([])
    const [citiesState, setCitiesState] = useState<any[]>([])
    const [selectedCity, setSelectedCity] = useState<any>()
    const [selectedCountry, setSelectedCountry] = useState<any>()
    const [sellType, setSellType] = useState<string>('')

    const updateSellType = async () => {
        if (sellType == '') return toast('Select sell type', { type: "error" })
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/settings/stock/update` }, { method: "POST", token: true, body: JSON.stringify({ 'stock_type': sellType }) })
        if (req.status) {
            setDisplayPage(false)
            toast(req.message, { type: "success" })
        }
        else {
            setDisplayPage(false)
            toast(req.message, { type: "error" })
        }
    }

    const getData = async () => {
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/settings` }, { method: "GET", token: true })
        if (req.status) {
            setDisplayMap(true)
            setDisplayPage(false)
            setSettingData(req.data)
            formRef.current['name'].value = req.data.name;
            formRef.current['trn'].value = req.data.trn;
            formRef.current['phone'].value = req.data.phone;
            formRef.current['address'].value = req.data.address;
            formRef.current['licence'].value = req.data.licences;
            localStorage.setItem("status", req.data.status)
            if (!checkisBrand()) {
                formRef.current['delivery_city_support'].checked = req.data.delivery_city_support;
                formRef.current['delivery_city_pay'].checked = req.data.delivery_city_pay;
                formRef.current['delivery_country_support'].checked = req.data.delivery_country_support;
                formRef.current['delivery_country_pay'].checked = req.data.delivery_country_pay;
                formRef.current['delivery_arabic_support'].checked = req.data.delivery_arabic_support;
                formRef.current['delivery_arabic_pay'].checked = req.data.delivery_arabic_pay;
                formRef.current['delivery_international_support'].checked = req.data.delivery_international_support;
                formRef.current['delivery_international_pay'].checked = req.data.delivery_international_pay;
            }
            if (checkisBrand()) {
                formRef.current['brand_name'].value = req?.data?.brand?.name ? req?.data?.brand?.name : "";
                formRef.current['RSPE_x'].value = req.data.RSPE_x;
                formRef.current['MSPE_x'].value = req.data.MSPE_x;
                formRef.current['RSPS_x'].value = req.data.RSPS_x;
                formRef.current['MSPS_x'].value = req.data.MSPS_x;
            }
            getCountriesToCustom(req.data.city)
            getCitiesToCustom(req.data.city.country_id, req.data.city)
            setSelectedImages({
                cover: req.data.cover.path ? req.data.cover : "",
                logo: req.data.logo.path ? req.data.logo : "",
                brandLogo: (req.data?.brand != null && req.data?.brand?.image?.path != null) ? req.data?.brand?.image : "",
            })
            Ctx.setShopLogo(req.data.logo.url)
            localStorage.setItem('logoSrc', req.data.logo.url)
            req.data.order_description && setOrderDescription(req.data.order_description)
            req.data.lat && req.data.lng && setPosition([req.data.lat, req.data.lng])
        } else {
            toast(req.message, { type: "error" })
        }
    }

    const checkImageRequired = () => {
        if (selectedImages?.cover.path == null) {
            toast("select image cover is required", { type: "info" })
            return false
        }
        if (selectedImages?.logo.path == null) {
            toast("select logo is required", { type: "info" })
            return false
        }
        if (checkisBrand() && selectedImages?.brandLogo.path == null) {
            toast("select image brand logo is required", { type: "info" })
            return false
        }
        return true
    }

    const updateSetting = async (e: any) => {
        e.preventDefault()
        if (!JSON.parse(selectedCity.value).value) toast("city is required", { type: "warning" })
        if (checkImageRequired() == false) return
        setDisplayPage(true)
        if (displayPage) return
        const postData: any = {
            'name': formRef.current['name'].value,
            'phone': formRef.current['phone'].value,
            'address': formRef.current['address'].value,
            'licences': formRef.current['licence'].value,
            'trn': formRef.current['trn'].value,
            'lat': position[0],
            'lng': position[1],
            'city_id': JSON.parse(selectedCity.value).value
        }
        if (selectedImages?.cover.path) postData['cover'] = selectedImages?.cover.path;
        if (selectedImages?.logo.path) postData['logo'] = selectedImages?.logo.path;

        if (checkisBrand()) postData['MSPE_x'] = formRef.current['MSPE_x'].value
        if (checkisBrand()) postData['RSPE_x'] = formRef.current['RSPE_x'].value
        if (checkisBrand()) postData['MSPS_x'] = formRef.current['MSPS_x'].value
        if (checkisBrand()) postData['RSPS_x'] = formRef.current['RSPS_x'].value

        if (!checkisBrand()) postData['delivery_city_support'] = formRef.current['delivery_city_support'].checked
        if (!checkisBrand()) postData['delivery_city_pay'] = formRef.current['delivery_city_pay'].checked
        if (!checkisBrand()) postData['delivery_country_support'] = formRef.current['delivery_country_support'].checked
        if (!checkisBrand()) postData['delivery_country_pay'] = formRef.current['delivery_country_pay'].checked
        if (!checkisBrand()) postData['delivery_arabic_support'] = formRef.current['delivery_arabic_support'].checked
        if (!checkisBrand()) postData['delivery_arabic_pay'] = formRef.current['delivery_arabic_pay'].checked
        if (!checkisBrand()) postData['delivery_international_support'] = formRef.current['delivery_international_support'].checked
        if (!checkisBrand()) postData['delivery_international_pay'] = formRef.current['delivery_international_pay'].checked

        if (checkisBrand()) postData['brand_name'] = formRef.current['brand_name'].value
        if (checkisBrand()) postData['brand_image'] = selectedImages?.brandLogo?.path
        for (var i = 0; i < orderDescription.length; i++) {
            postData[`order_description[${i}]`] = orderDescription[i]
        }
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/settings` }, { method: "POST", body: JSON.stringify(postData), token: true })
        if (req.status) {
            toast(req.message, { type: "success" })
            setDisplayPage(false)
            getData()
            const form: any = document.getElementById("orderDescForm")
            form?.reset()
        } else {
            setDisplayPage(false)
            toast(req.message, { type: "error" })
        }
    }

    useEffect(() => {
        getData()
        Ctx.setHeadTitle("Settings")
        Ctx.setActiveItemNav({ 'menu': 'settings', 'subMenu': 0 })
    }, [Ctx.shopLogo])


    function onChangeOrderDescription(e: any, index: number) {
        let lastList = []
        lastList = [...orderDescription]
        for (var i = 0; i < lastList.length; i++) {
            if (i === index) {
                lastList[i] = e.target.value
            }
        }
        setOrderDescription([...lastList])
    }

    function addNewOrderDescription() {
        let lastList = []
        lastList = [...orderDescription]
        lastList.push("")
        setOrderDescription([...lastList])
    }

    function removeOrderDescription(index: any) {
        let lastList = []
        lastList = [...orderDescription]
        for (var i = 0; i < lastList.length; i++) {
            if (i === index) {
                lastList.splice(i, 1)
            }
        }
        setOrderDescription([...lastList])
    }

    const onChangeImage = async (response: any, name: string) => {
        let data: any = { ...selectedImages };
        data[name] = response;
        setSelectedImages(data)
    };

    const getCountriesToCustom = async (currentId: any) => {
        const iniCountries = await GetCountriesAPi()
        const finalList: any[] = iniCountries.data.map((e: any) => { return { label: e.name, value: JSON.stringify({ label: e.name.toLowerCase(), value: e.id }) } })
        setCountriesState([...finalList])
        const foundIndex = finalList.findIndex((e: any) => JSON.parse(e.value).value == currentId.country_id)
        setSelectedCountry(finalList[foundIndex])
    }

    const getCitiesToCustom = async (idParam: any, currentId?: any) => {
        const iniCities = await GetCitiesAPi(idParam)
        const finalList = iniCities.data.map((e: any) => { return { label: e.name, value: JSON.stringify({ label: e.name.toLowerCase(), value: e.id }) } })
        setCitiesState([...finalList])
        const foundIndex = finalList.findIndex((e: any) => JSON.parse(e.value).value == currentId.id)
        setSelectedCity(finalList[foundIndex])
    }

    useEffect(() => {
    }, [])

    return (
        <div className='flex justify-between flex-wrap'>
            {displayPage && <LoadingPage />}
            {checkisBrand() &&
                <div className=' w-full md:w-6/12 lg:w-4/12 h-[200px]  flex flex-col order-1 md:order-2 '>
                    <div className=" w-full flex flex-col  mt-5">
                        <span className=" text-glasses-text-50 text-sm mb-1">Select sell type</span>
                        <select onChange={(e: any) => { setSellType(e.target.value) }} className=" add-form-sel w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required>
                            <option value={''} className=" text-gray-400" selected disabled hidden>{`select type`}</option>
                            <option value={'in_stock'}>in stock</option>
                            <option value={'by_stock'}>by stock</option>
                        </select>
                        <button onClick={updateSellType} className=" text-white rounded-lg bg-glasses-head-50 w-[120px] h-[40px] mt-9 font-bold ">Update</button>
                    </div>
                </div>
            }

            <div className=" w-full md:w-6/12 lg:w-4/12  flex flex-col order-2 md:order-1">
                <form ref={formRef} id='orderDescForm' onSubmit={updateSetting} className=" w-full">
                    <div className=" w-full flex flex-col mt-5">
                        <span className=" text-glasses-text-50 text-sm">Name</span>
                        <input name='name' type="text" className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required />
                    </div>

                    <div className=" w-full flex flex-col mt-5">
                        <span className=" text-glasses-text-50 text-sm">Phone</span>
                        <input name="phone" type="text" className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required />
                    </div>

                    <div className=" w-full flex flex-col  mt-5">
                        <span className=" text-glasses-text-50 text-sm">Country</span>
                        <Select
                            loading={countriesState.length === 0}
                            showSearch
                            placeholder="Search countries"
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                            filterSort={(optionA: any, optionB: any) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            value={selectedCountry}
                            onChange={(e) => {
                                setSelectedCountry(JSON.parse(e))
                                getCitiesToCustom(JSON.parse(e).value)
                            }
                            }
                            options={countriesState}
                            className=" w-full h-[34px] flex items-center rounded-xl bg-white mt-3 shadow px-2 focus:outline-none text-sm text-glasses-text-50"
                        />
                    </div>

                    <div className=" w-full flex flex-col  mt-5">
                        <span className=" text-glasses-text-50 text-sm">City</span>
                        <Select
                            loading={countriesState.length === 0}
                            showSearch
                            placeholder={`${citiesState.length === 0 ? "Choose country " : "Choose city"}`}
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                            filterSort={(optionA: any, optionB: any) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            value={selectedCity}
                            onChange={(e) => setSelectedCity({ label: JSON.parse(e).label, value: e })}
                            disabled={citiesState.length === 0}
                            options={citiesState}
                            className=" w-full h-[34px] flex items-center rounded-xl bg-white mt-3 shadow px-2 focus:outline-none text-sm text-glasses-text-50"
                        />
                    </div>

                    <div className=" w-full flex flex-col mt-5">
                        <span className=" text-glasses-text-50 text-sm">Address</span>
                        <textarea name="address" className=" min-h-[100px] w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required />
                    </div>



                    <div className=" w-full flex flex-col mt-5">
                        <span className=" text-glasses-text-50 text-sm">Licence</span>
                        <input name='licence' type="text" className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required />
                    </div>


                    {!checkisBrand() &&
                        <>
                            <div className=" w-full flex flex-col mt-5">
                                <span className=" text-glasses-text-50 text-sm">Delivery</span>
                                <div className='w-full flex flex-col rounded-xl bg-white shadow sm:px-6 px-2  mt-3'>

                                    <div className='w-full flex items-center justify-between border-b py-3'>
                                        <label className=' flex items-center cursor-pointer'>
                                            <input name='delivery_city_support' type={'checkbox'} />
                                            <span className='ml-2'>Support City</span>
                                        </label>
                                        <div className='flex items-center'>
                                            <span className='text-[11px] mr-1'>customer</span>
                                            <div className='flex flex-col items-center justify-center '>
                                                <span className='text-[11px] mb-1'>Shipping</span>
                                                <label className="table-toggle flex">
                                                    <input type="checkbox" name='delivery_city_pay' />
                                                    <div className="table-toggle-main" />
                                                </label>
                                            </div>
                                            <span className='text-[11px] ml-1'>shop</span>
                                        </div>
                                    </div>

                                    <div className='w-full flex items-center justify-between border-b py-3'>
                                        <label className=' flex items-center cursor-pointer'>
                                            <input name='delivery_country_support' type={'checkbox'} />
                                            <span className='ml-2'>Support Country</span>
                                        </label>
                                        <div className='flex items-center'>
                                            <span className='text-[11px] mr-1'>customer</span>
                                            <div className='flex flex-col items-center justify-center '>
                                                <span className='text-[11px] mb-1'>Shipping</span>
                                                <label className="table-toggle flex">
                                                    <>
                                                        <input type="checkbox" name='delivery_country_pay' />
                                                        <div className="table-toggle-main" />
                                                    </>
                                                </label>
                                            </div>
                                            <span className='text-[11px] ml-1'>shop</span>
                                        </div>
                                    </div>

                                    <div className='w-full flex items-center justify-between border-b py-3'>
                                        <label className=' flex items-center cursor-pointer'>
                                            <input name='delivery_arabic_support' type={'checkbox'} />
                                            <span className='ml-2'>Support Arabic</span>
                                        </label>
                                        <div className='flex items-center'>
                                            <span className='text-[11px] mr-1'>customer</span>
                                            <div className='flex flex-col items-center justify-center '>
                                                <span className='text-[11px] mb-1'>Shipping</span>
                                                <label className="table-toggle flex">
                                                    <>
                                                        <input type="checkbox" name='delivery_arabic_pay' />
                                                        <div className="table-toggle-main" />
                                                    </>
                                                </label>
                                            </div>
                                            <span className='text-[11px] ml-1'>shop</span>
                                        </div>
                                    </div>

                                    <div className='w-full flex items-center justify-between py-3'>
                                        <label className=' flex items-center cursor-pointer'>
                                            <input name='delivery_international_support' type={'checkbox'} />
                                            <span className='ml-2'>Support International</span>
                                        </label>
                                        <div className='flex items-center'>
                                            <span className='text-[11px] mr-1'>customer</span>
                                            <div className='flex flex-col items-center justify-center '>
                                                <span className='text-[11px] mb-1'>Shipping</span>
                                                <label className="table-toggle flex">
                                                    <>
                                                        <input type="checkbox" name='delivery_international_pay' />
                                                        <div className="table-toggle-main" />
                                                    </>
                                                </label>
                                            </div>
                                            <span className='text-[11px] ml-1'>shop</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </>
                    }

                    <div className=" w-full flex flex-col mt-5">
                        <span className=" text-glasses-text-50 text-sm">TRN  </span>
                        <input name='trn' type="text" className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required />
                    </div>

                    {checkisBrand() &&
                        <>
                            <div className=" w-full flex flex-col mt-5">
                                <span className=" text-glasses-text-50 text-sm">Brand name ( platform )</span>
                                <input name='brand_name' type="text" className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required />
                            </div>

                            <div className=' flex w-full'>
                                <div className=" w-full flex flex-col mt-5 mx-1">
                                    <span className=" text-glasses-text-50 text-sm mb-2">Platform brand logo</span>
                                    <UploadWP imageRatio={1} currentIMG={selectedImages?.brandLogo?.url} setResponse={(res: any) => onChangeImage(res, "brandLogo")}
                                        url={`${localStorage.getItem("shopId")}/settings/logo_brand`} fileCount={1} />
                                </div>
                            </div>
                        </>
                    }

                    <div className=" w-full flex flex-col mt-5">
                        <span className=" text-glasses-text-50 text-sm">Order Descriptions</span>
                        {orderDescription.length !== 0 && orderDescription.map((items: any, index) =>
                            <div className=' flex relative'>
                                <input placeholder='insert description'
                                    key={index} value={items} onChange={(e) => { onChangeOrderDescription(e, index) }} type="text" className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required />
                                <span onClick={() => removeOrderDescription(index)} className=' text-red-500 right-[-30px] top-3 font-bold cursor-pointer absolute bg-gray-300 rounded-full p-1 flex justify-center items-center h-6 w-6'>X</span>
                            </div>
                        )}
                        <div onClick={() => addNewOrderDescription()} className=' w-full mt-2 justify-center flex cursor-pointer'> add new +</div>
                    </div>

                    <div className=' w-full flex  h-[350px] rounded-[30px] overflow-hidden my-4 '>
                        {displayMap &&
                            <MapCont id="mapInSetting" enableChangeMarker={true} position={position} setPosition={setPosition} />
                        }
                    </div>

                    <div className=' flex w-full'>
                        <div className=" w-full flex flex-col mt-5 mx-1">
                            <span className=" text-glasses-text-50 text-sm mb-2">Cover</span>
                            <UploadWP imageRatio={1.8} currentIMG={selectedImages?.cover.url} setResponse={(res: any) => onChangeImage(res, "cover")}
                                url={`${localStorage.getItem("shopId")}/settings/cover`} fileCount={1} />
                        </div>

                        <div className=" w-full flex flex-col mt-5 mx-1">
                            <span className=" text-glasses-text-50 text-sm mb-2">Dashboard logo</span>
                            <UploadWP imageRatio={1} currentIMG={selectedImages?.logo.url} setResponse={(res: any) => onChangeImage(res, "logo")}
                                url={`${localStorage.getItem("shopId")}/settings/logo`} fileCount={1} />
                        </div>
                    </div>

                    {checkisBrand() &&
                        <div className="w-full flex flex-col  mt-4 p-2">
                            <div className='border-b-2 pb-1 text-lg'>Price controlling</div>
                            <div className=" w-full flex flex-col mt-5">
                                <span className=" text-glasses-text-50 text-sm">Suggested retail price Sunglasses </span>
                                <input step=".01" name='RSPS_x' type="number"
                                    className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" />
                            </div>
                            <div className=" w-full flex flex-col mt-5">
                                <span className=" text-glasses-text-50 text-sm">Minimum selling price Sunglasses </span>
                                <input step=".01" name='MSPS_x' type="number"
                                    className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" />
                            </div>

                            <div className=" w-full flex flex-col mt-5">
                                <span className=" text-glasses-text-50 text-sm">Suggested retail price EyeGlasses </span>
                                <input step=".01" name='RSPE_x' type="number"
                                    className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" />
                            </div>
                            <div className=" w-full flex flex-col mt-5">
                                <span className=" text-glasses-text-50 text-sm">Minimum selling price EyeGlasses </span>
                                <input step=".01" name='MSPE_x' type="number"
                                    className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" />
                            </div>
                        </div>
                    }

                    <button className=" text-white rounded-lg bg-glasses-head-50 w-[120px] h-[40px] mt-9 font-bold ">Update Profile </button>
                </form>

            </div>
        </div>
    );
};

export default Settings;
