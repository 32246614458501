import React, { useState, useContext, useEffect } from 'react';
import { ContextState } from '../../widget/context/ContextMain'
import BtnLoader from '../../widget/Loading/BtnLoader';
import { customApi, baseUrl, ImageUrl } from '../../widget/apis';
import LoadingPage from '../../widget/Loading/LoadingPage';
import { toast } from "react-toastify";
import TablePage from '../../widget/Table/TablePage';
import { Link } from 'react-router-dom';
import CModal from '../../widget/CModal/CModal';
import Cleave from 'cleave.js/react';
import { AddCouponAPi } from '../../widget/apis/coupon';
import { Popover } from 'antd';


function Credits() {
    const Ctx = useContext(ContextState);
    const [displayPage, setDisplayPage] = useState<any>(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [listData, setListData] = useState<any[]>([]);
    const [lastPage, setLastPage] = useState(0);
    const [visible, setVisible] = React.useState<boolean>(false);
    const [selectShop, setSelectShop] = useState<any>();


    const col = [
        {
            title: "Logo",
            render: (i: any) => (<>{i.logo && i.logo != null && <img src={i.logo} className="w-[50px] h-[50px] my-3 rounded-full" />}</>)
        },
        {
            title: "Name",
            render: (i: any) => (<>{i.name != null && i.name}</>)
        },
        {
            title: "Credit days",
            render: (i: any) => (<>{i.credit_days != null && i.credit_days}</>)
        },
        {
            title: "Change credit days",
            render: (i: any) => (
                <button onClick={() => startChange(i)} className="  h-[35px] px-3 text-white rounded-xl bg-glasses-head-50 flex items-center justify-center">
                    <svg x="0px" y="0px" viewBox="0 0 469.331 469.331" className={` w-4  fill-white transition-all  `}>
                        <use xlinkHref="/assets/svg/edit.svg#edit" />
                    </svg>
                </button>
            )
        },
        {
            title: <div className=' flex '>
                <div className=' mr-4 '>Change Status</div>
                <Popover placement='bottomLeft' content="Disabling the status of retail shops means they will not be able to see/order from your brand in the platform">
                    <div className=' w-6 h-6 flex items-center justify-center bg-glasses-head-50 text-white rounded'>
                        ?
                    </div>
                </Popover>
            </div>,
            render: (i: any) => (
                <>
                    <div className=' flex items-center '>
                        <label key={i.id} className="table-toggle">
                            <>
                                <input type="checkbox" onChange={() => handelActive(i)} checked={i.status} />
                                <div className="table-toggle-main" />
                            </>
                        </label>
                    </div>

                </>
            )
        }
    ]

    const handelActive = async (item: any) => {
        setDisplayPage(true)
        let postData: any = {
            "days": item.credit_days,
            "shop_id": item.id,
            "status": !item.status,
        }
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/credits` }, { method: "POST", token: true, body: JSON.stringify(postData) })
        setDisplayPage(false)
        if (req.status) {
            getData(currentPage)
            toast(req.message, { type: "success" })
        }
        else {
            toast(req.message, { type: "error" })
        }
    }

    const startChange = (item: any) => {
        setVisible(true)
        setSelectShop(item)
    }

    const onFinish = async (e: any) => {
        e.preventDefault()
        setDisplayPage(true)
        let postData: any = {
            "days": e.target[0].value,
            "shop_id": selectShop.id,
            "status": selectShop.status,
        }
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/credits` }, { method: "POST", token: true, body: JSON.stringify(postData) })
        setDisplayPage(false)
        if (req.status == true) {
            toast(req.message, { type: "success" })
            setVisible(false)
            getData(currentPage)
        }
        else {
            toast(req.message, { type: "error" })
        }
    }

    const changePage = (status: any) => {
        if (status) {
            if (lastPage >= currentPage + 1) {
                getData(currentPage + 1)
            }
        }
        else {
            if (currentPage > 1) {
                getData(currentPage - 1)
            }
        }
    }


    const getData = async (current: any) => {
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/credits?page=${current}` }, { method: "GET", token: true })
        setDisplayPage(false)
        if (req.status == true) {
            setListData([...req.data])
        }
    }

    useEffect(() => {
        Ctx.setHeadTitle("Credits")
        Ctx.setActiveItemNav({ 'menu': 'credits', 'subMenu': 0 })
        getData(currentPage)
    }, [])

    return (
        <>
            {displayPage && <LoadingPage />}
            <div className=" w-full  flex flex-col   ">
                <TablePage columns={col} data={listData}>
                </TablePage>
            </div>

            <CModal title='Change credit days' setVisible={setVisible} visible={visible} uId='creditModal' >
                <>
                    <form onSubmit={onFinish} className=' w-full flex flex-col flex-wrap justify-between items-start px-4'>

                        <div className=" w-10/12 flex flex-col my-5">
                            <span className=" text-glasses-text-50 text-sm">Credit days</span>
                            <input type="number" className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required />
                        </div>

                        <button className=" text-white rounded-lg bg-glasses-head-50 w-[120px] h-[40px] mt-9 font-bold ">Update </button>

                    </form>
                </>
            </CModal>
        </>
    );
}

export default Credits;
