import React, {useState} from 'react';
import TablePage from '../../../widget/Table/TablePage';

interface IDetailsModalProps {
    item: any
}

const DetailsModal: React.FunctionComponent<IDetailsModalProps> = ({ item }) => {

    const col = [
        {
            title: "Image",
            render: (i: any) => <div className=' p-2 flex justify-center'><img className=' w-10/12' src={i.image} /></div>
        },
        {
            title: "Name",
            render: (i: any) => <div>{i.name && i.name}</div>
        },
        {
            title: "Unit Price",
            render: (i: any) => <div>{i.unit_price && i.unit_price}</div>
        },
        {
            title: "Count",
            render: (i: any) => <div>{i.count && i.count}</div>
        },
        // {
        //     title: "Price",
        //     render: (i: any) => <div className=' text-green-600'> {i.price && i.price.toFixed(2)} AED</div>
        // },
        {
            title: "Vat (+10.5%)",
            render: (i: any) => <div className=' text-red-600'> {i.fee && i.fee} AED</div>
        },
        {
            title: "Hyperpay commission (-%5.72)",
            render: (i: any) => <div className=' text-red-600'> {i.bank && i.bank} AED</div>
        },
        {
            title: "Per transaction cost (-1 AED)",
            render: (i: any) => <div className=' text-red-600'> {i.aed && i.aed} AED</div>
        },
        {
            title: "Platform commission (-%21)",
            render: (i: any) => <div className=' text-red-600'> {i.optic && i.optic} AED</div>
        },
        {
            title: "Delivery fee",
            render: (i: any) => <div className={`${i.delivery_fee < 0 ? 'text-red-600' : 'text-gray-700' }  `}> {i.delivery_fee && i.delivery_fee.toFixed(2)} AED</div>
        },
        {
            title: "Final Price",
            render: (i: any) => <div className=' text-green-700 text-lg'> {i.final_price && i.final_price.toFixed(2)} AED</div>
        },
    ]


    return (
        <div className=' px-4'>
            <TablePage columns={col} data={item.products} />
            <div className=' flex w-full justify-end '>
                <div className=' flex justify-center w-fit items-center text-2xl pt-0 p-8'>
                    Total : <span className=' bg-green-100 rounded-xl px-2 py-1 ml-2 font-bold text-green-700'> {item.total_price} AED</span>
                </div>
            </div>
        </div>
    );
};

export default DetailsModal;
