import React, { useState, useContext, useEffect } from 'react';
import { ContextState } from '../../../widget/context/ContextMain'
import BtnLoader from '../../../widget/Loading/BtnLoader';
import { customApi, baseUrl, DownloadInvoiceApi, DownloadPDFApi } from '../../../widget/apis';
import LoadingPage from '../../../widget/Loading/LoadingPage';
import { toast } from "react-toastify";
import TablePage from '../../../widget/Table/TablePage';
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { StatusRender } from '../order-list/OrderList';
import { checkisBrand, checkPermission } from '../../../widget/layout/Nav';
import { OrdersPermissions } from '../../../widget/rolePermissions';
import CModal from '../../../widget/CModal/CModal';
import ModalSendOrders from './OrderDetailModal/ModalSendOrders';

function OrderDetail() {
  const Ctx = useContext(ContextState);
  const [displayBtn, setDisplayBtn] = useState<any>(false)
  const [displayPage, setDisplayPage] = useState<any>(false)
  const [visibleSendOrder, setVisibleSendOrder] = useState<any>(false)
  const [detailData, setDetailData] = useState<any>(null)
  const [TermDetailData, setTermDetailData] = useState<any>(null)
  let params = useParams();

  const downloadInvoice = async () => {
    setDisplayPage(true)
    try {
      const res: any = await DownloadInvoiceApi(params.id)
      var el = document.createElement("a");
      el.setAttribute("href", res);
      el.setAttribute("download", 'invoice');
      el.setAttribute("target", '_blank');
      document.body.appendChild(el);
      el.click();
      el.remove();
      setDisplayPage(false)
    } catch {
      setDisplayPage(false)
    }
  }
  
  const downloadPDF = async () => {
    setDisplayPage(true)
    try {
      const res: any = await DownloadPDFApi(params.id)
      var el = document.createElement("a");
      el.setAttribute("href", res);
      el.setAttribute("download", 'PDF');
      el.setAttribute("target", '_blank');
      document.body.appendChild(el);
      el.click();
      el.remove();
      setDisplayPage(false)
    } catch {
      setDisplayPage(false)
    }
  }

  const changeStatus = async () => {
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/orders/${params.id}/send` }, { method: "GET", token: true })
    setDisplayPage(false)
    if (req.status == true) {
      toast(req.message, { type: "success" })
      viewDetail()
    }
    else {
      toast(req.message, { type: "error" })
    }
  }

  const viewDetail = async () => {
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/orders/${params.id}` }, { method: "GET", token: true })
    setDisplayPage(false)
    if (req.status == true) {
      setDetailData({ ...req.data });
      setTermDetailData({ ...req.data })
      Ctx.setHeadTitle(`Order ${req.data.name}`)
    }
  }

  useEffect(() => {
    Ctx.setHeadTitle("Order")
    Ctx.setActiveItemNav({ 'menu': 'orders', 'subMenu': 0 })
    viewDetail()
  }, [])


  return (
    <>
      {displayPage && <LoadingPage />}
      {detailData != null &&
        <div className=" w-full  flex flex-col ">
          <div className=" w-full h-[60px] flex justify-between items-center">
            <StatusRender isAbsolute status={detailData.status} />
            {/* <div className="  px-[10px] h-[40px] text-center bg-glasses-head-50 rounded-xl text-white flex justify-center items-center">{detailData.status}</div> */}
            {detailData.status != -1 &&
              <div className="flex items-center">
                <button onClick={downloadInvoice} className=" px-[10px] h-[40px] mr-3 bg-glasses-head-50 rounded-xl text-white">Download invoice</button>
                {checkPermission(OrdersPermissions.update) &&
                  <>
                    {detailData.status == 1 || detailData.status == 8 ?
                      <>
                        {checkisBrand() ?
                          <div onClick={() => { setVisibleSendOrder(true) }} className=" min-w-[100px] px-[10px] h-[40px] text-center text-[#fff] bg-[#10af45] flex justify-center items-center cursor-pointer rounded-xl focus:outline-none">
                            Confirm order
                          </div>
                          :
                          <div onClick={changeStatus} className=" min-w-[100px] px-[10px] h-[40px] text-center text-[#fff] bg-[#10af45] flex justify-center items-center cursor-pointer rounded-xl focus:outline-none">
                            Send order
                          </div>
                        }
                      </>
                      :
                      <div className=" min-w-[100px] px-[10px] h-[40px] text-center bg-[#ddd] text-[#fff] flex justify-center items-center cursor-not-allowed rounded-xl focus:outline-none">
                        Send order
                      </div>
                    }
                  </>
                }
              </div>
            }
            {(detailData.status == 2 || detailData.status == 3 || detailData.status == 4 || detailData.status == 5) &&
              <>
                {!checkisBrand() &&
                  <button onClick={downloadPDF} className=" px-[10px] h-[40px] mr-3 bg-glasses-head-50 rounded-xl text-white">Print airway bill</button>
                }
              </>
            }
          </div>
          <div className=" w-full flex flex-wrap mt-[20px]">
            <div className=" w-full md:w-6/12  flex flex-col">
              <div className=" w-full flex flex-col ">
                <h3 className=" ml-3 text-glasses-text-50 text-xl font-bold">Receiver information</h3>
                <div className=" w-full p-[10px] sm:p-[20px] bg-white rounded-xl shadow flex flex-col">
                  <div className=" w-full  mb-[10px] flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 490.1 490.1" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/user.svg#user" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold"> Name</span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{detailData.receive_name}</span>
                  </div>
                  <div className=" w-full mb-[10px]   flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 19 32" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/phone.svg#phone" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold"> Phone</span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{detailData.receive_phone} </span>
                  </div>
                  <div className=" w-full mb-[10px]   flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 368.666 368.666" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/location2.svg#XMLID_2_" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold"> Address </span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{detailData.receive_address} </span>
                  </div>
                </div>
              </div>

              {detailData.shop &&
                <div className=" w-full flex flex-col  md:mt-[50px] mt-[30px]">
                  <h3 className=" ml-3 text-glasses-text-50 text-xl font-bold">Shop information</h3>
                  <div className=" w-full p-[10px] sm:p-[20px] bg-white rounded-xl shadow flex items-center">
                    <img src={detailData.shop.logo} alt='logo' className='w-[50px] h-[50px] rounded-full border' />
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{detailData.shop.name}</span>
                  </div>
                </div>
              }

              <div className=" w-full flex flex-col md:mt-[50px] mt-[30px] ">
                <h3 className=" ml-3 text-glasses-text-50 text-xl font-bold">Payment Information</h3>
                <div className=" w-full p-[10px] sm:p-[20px] bg-white rounded-xl shadow flex flex-col">
                  <div className=" w-full  mb-[10px] flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 490.1 490.1" className={` w-4 h-4 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/user.svg#user" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold">User Name</span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{detailData.client.name} </span>
                  </div>
                  <div className=" w-full mb-[10px]   flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 488.1 488.1" className={` w-5 h-5 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/price.svg#price" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold" >Total Payment</span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{TermDetailData.total_payment} AED </span>
                  </div>
                  <div className=" w-full mb-[10px]   flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 20 20" className={` w-5 h-5 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/date.svg#date" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold">Purchase Date </span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{detailData.pay_date} </span>
                  </div>
                  <div className=" w-full mb-[10px]   flex items-center flex-wrap ">
                    <button className=" w-[35px] h-[35px] border rounded flex justify-center items-center">
                      <svg x="0px" y="0px" viewBox="0 0 206.5 206.5" className={` w-5 h-5 fill-glasses-text-50 transition-all   `}>
                        <use xlinkHref="/assets/svg/checkpay.svg#checkpay" />
                      </svg>
                    </button>
                    <span className="mx-[10px] text-[#836c77] font-bold">Payment {checkisBrand() ? "Term" : "Method"}</span>
                    <span className="mx-[10px] text-glasses-text-50 font-bold">{detailData.payment_term} </span>
                  </div>
                </div>
              </div>

            </div>

            <div className=" w-full md:w-6/12 mt-[30px] md:mt-0  flex justify-end">
              <div className=' w-full lg:w-8/12 md:w-11/12  flex flex-col'>
                <div className='w-full flex flex-col'>
                  <h3 className=" ml-3 text-glasses-text-50 text-xl font-bold">Items</h3>
                  {detailData.products && detailData.products.map((item: any, index: any) =>
                    <div key={index} className=" w-full p-[10px] sm:p-[20px] mb-[10px] bg-white rounded-xl shadow flex items-start ">
                      <img src={item.image ? item.image : "/assets/svg/shopping.svg#shoping-g"} alt="cover" className=' w-[120px] rounded-lg mr-2' />
                      <div className=' w-[calc(100%-120px)] pl-[10px]  flex flex-col'>
                        <span className="text-glasses-text-50 font-bold">{item.name}</span>
                        <div className=' flex items-center mt-[5px]'>
                          <span className=" text-[#836c77]  text-lg">{item.count} x </span>
                          {item.color_image &&
                            <img src={item.color_image ? item.color_image : "/assets/svg/box.svg"} alt="cover" className=' ml-[15px] w-[20px] h-[20px] rounded-full' />
                          }
                          <div className="min-w-[56px] border flex flex-col justify-center items-center px-1 ml-2 rounded-xl text-[11px] bg-white">
                            <small className="">{item.color_image ? 'Frame Size' : 'Sph'}</small>
                            {item.color_image ? item.frame_size : item.sph}
                          </div>
                        </div>
                        <span className='text-[#FFA500] text-xl mt-[10px]'>{item.price} AED</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <ModalSendOrders
            visible={visibleSendOrder}
            setVisible={setVisibleSendOrder}
            detailData={detailData}
            setDetailData={setDetailData}
            setDisplayPage={setDisplayPage}
            viewDetail={viewDetail}
          />

        </div>
      }




    </>
  );
}

export default OrderDetail;
