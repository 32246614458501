import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useRoutes } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { ContextState } from '../../widget/context/ContextMain';
import { baseUrl, customApi } from '../apis';
import LoadingPage from '../Loading/LoadingPage';
import { CouponsPermissions, CreditsPermissions, NotoficationPermissions, OperatorsPermissions, OrdersPermissions, ProductsPermissions, ProfilePermissions, PurchasesPermissions, SettingPermissions, StatementPermissions, StockReportPermissions, WalletPermissions , RefundPermissions } from '../rolePermissions';


export function checkisBrand() {
  const isBrand: any = localStorage.getItem("isBrand")
  if (Boolean(isBrand?.length)) return JSON.parse(isBrand)
}

export function checkPermission(key: any) {

  const UPLocal: any = localStorage.getItem("role")
  const userPermission: any = JSON.parse(UPLocal)
  var bool = false
  if (key == "allowed") return true
  for (var i in userPermission?.permissions) {
    if (userPermission.permissions[i].name == key) {
      bool = true
    }
  }
  return bool
}

function Nav(props: any) {
  const Ctx = useContext(ContextState);
  const [isChooseShop, setIsChooseShop] = useState<boolean>(false)
  const location = useLocation();
  const navigate = useNavigate();
  const [navData, setNavData] = useState<any[]>([])
  const [listShops, setListShops] = useState<any[]>([])
  const [filterName, setFilterName] = React.useState<string>("");
  const [displayPage, setDisplayPage] = useState<any>(false)

  const NavData = [
    {
      'name': 'Dashboard',
      'slug': 'dashboard',
      'path': '/panel/dashboard',
      'svgLink': "/assets/svg/dashboard.svg#dashboard",
      'svgView': "0 0 512 512",
      'access': "all",
      'isSubmenu': false,
      'rolePermission': "allowed",
    },
    {
      'name': 'Products',
      'slug': 'products',
      'path': '/panel/product/list',
      'svgLink': "/assets/svg/box.svg#box",
      'svgView': "0 0 490 490",
      'access': "all",
      'isSubmenu': false,
      'rolePermission': ProductsPermissions.view
    },
    {
      'name': 'Inventory',
      'slug': 'stock-report',
      'path': '/panel/stock-report',
      'svgLink': "/assets/svg/stock-rep.svg#stock-rep",
      'svgView': "0 0 512 512",
      'access': "all",
      'isSubmenu': false,
      'rolePermission': StockReportPermissions.view,
    },
    {
      'name': 'Price Controller',
      'slug': 'price-controller',
      'path': '/panel/price-controller',
      'svgLink': "/assets/svg/stock-rep.svg#stock-rep",
      'svgView': "0 0 512 512",
      'access': "brand",
      'isSubmenu': false,
      'rolePermission': StockReportPermissions.view,
    },
    {
      'name': 'Orders',
      'slug': 'orders',
      'path': '/panel/order/list',
      'svgLink': "/assets/svg/order.svg#logoutt",
      'svgView': "0 0 57 57",
      'access': "all",
      'isSubmenu': false,
      'rolePermission': OrdersPermissions.view
    },
    {
      'name': ' My purchases ',
      'slug': 'my_orders',
      'svgLink': "/assets/svg/myorder.svg#myorder",
      'svgView': "0 0 60 60",
      'access': "shop",
      'isSubmenu': true,
      'rolePermission': PurchasesPermissions.view,
      'submenu': [
        {
          'name': 'Pending',
          'path': "/panel/my-order"
        },
        {
          'name': 'Received',
          'path': "/panel/my-order-recived"
        },
      ]
    },
    {
      'name': 'Operators',
      'slug': 'operators',
      'svgLink': "/assets/svg/users.svg#users",
      'svgView': "0 0 35.695 35.695",
      'access': "all",
      'isSubmenu': true,
      'rolePermission': OperatorsPermissions.view,
      'submenu': [
        {
          'name': 'Users',
          'path': "/panel/operator-user"
        },
        {
          'name': 'Roles',
          'path': "/panel/operator-roles"
        },
      ]
    },
    {
      'name': 'Statement',
      'slug': 'statement',
      'path': '/panel/statement',
      'svgLink': "/assets/svg/statement.svg#statement",
      'svgView': "0 0 512 512",
      'access': "shop",
      'isSubmenu': false,
      'rolePermission': StatementPermissions.view,
    },
    // {
    //   'name': 'Brand',
    //   'slug': 'brand',
    //   'path': '/panel/brand',
    //   'svgLink': "/assets/svg/brand.svg#brand",
    //   'svgView': "0 0 290.476 290.476",
    //   'access': "brand",
    //   'isSubmenu': false,
    // },
    {
      'name': 'Wallet',
      'slug': 'wallet',
      'path': '/panel/my-wallet',
      'svgLink': "/assets/svg/wallet.svg#wallet",
      'svgView': "0 0 374.78 374.78",
      'access': "shop",
      'isSubmenu': false,
      'rolePermission': WalletPermissions.view,
    },
    {
      'name': 'Refund',
      'slug': 'refund',
      'path': '/panel/refund',
      'svgLink': "/assets/svg/refresh-white.svg#refresh",
      'svgView': "0 0 32 32",
      'access': "all",
      'isSubmenu': false,
      'rolePermission': WalletPermissions.view,
    },
    {
      'name': 'Profile',
      'slug': 'profile',
      'path': '/panel/profile',
      'svgLink': "/assets/svg/person.svg#person",
      'svgView': "0 0 16 16",
      'access': "all",
      'isSubmenu': false,
      'rolePermission': "allowed",
    },
    {
      'name': 'credits',
      'slug': 'credits',
      'path': '/panel/credits',
      'svgLink': "/assets/svg/credits.svg#credits",
      'svgView': "0 0 185.458 185.458",
      'access': "brand",
      'isSubmenu': false,
      'rolePermission': CreditsPermissions.view,
    },
    {
      'name': 'Settings',
      'slug': 'settings',
      'path': '/panel/setting',
      'svgLink': "/assets/svg/setting.svg#setting",
      'svgView': "0 0 1024 1024",
      'access': "all",
      'isSubmenu': false,
      'rolePermission': CreditsPermissions.view,
    },
    {
      'name': 'Notification',
      'slug': 'notification',
      'path': '/panel/notification',
      'svgLink': "/assets/svg/setting.svg#setting",
      'svgView': "0 0 1024 1024",
      'access': "all",
      'isSubmenu': false,
      'rolePermission': SettingPermissions.view,
    },
    {
      'name': 'Coupon',
      'slug': 'coupon',
      'path': '/panel/coupon',
      'svgLink': "/assets/svg/coupon.svg#coupon",
      'svgView': "0 0 511.999 511.999",
      'access': "all",
      'isSubmenu': false,
      'rolePermission': CouponsPermissions.view,
    },
  ]

  const logOut = () => {
    navigate('/');
    localStorage.clear()
    Ctx.setShopLogo(null)
    toast("Logged out successfully", { type: "success" })
  }

  useEffect(() => {
    if (window.innerWidth < 768) {
      props.setDisplayNav(true)
    }
    window.addEventListener('resize', () => {
      if (window.innerWidth < 768) {
        props.setDisplayNav(true)
      }
      else {
        // props.setDisplayNav(false)
      }
    })
  }, [])


  useEffect(() => {
    const shopID: any = localStorage.getItem("shopId")
    if (Boolean(shopID)) {
      getShops()
      changeShop(+shopID)
    } else getIniShops()
    const nav = document.getElementById('nav');
    const bgNav = document.getElementById('bg-nav');
    window.addEventListener('click', function (e: any) {
      if (bgNav!.contains(e.target)) {
        if (!nav!.contains(e.target)) {
          props.setDisplayNavMobile(false)
        }
      }
    });
  }, [])


  useEffect(() => {
    props.setDisplayNavMobile(false)
    const logoSrc = localStorage.getItem("logoSrc")
    logoSrc && Ctx.setShopLogo(logoSrc)
    const completeProfile = localStorage.getItem("status")
    if (completeProfile === "2") {
      toast("Please Complete The Profile", { type: "info", autoClose: false })
      if (location.pathname !== "/panel/setting") (
        navigate('/panel/setting')
      )
    }
  }, [location]);

  const getIniShops = async () => {
    const firstShop: any = await getShops()
    changeShop(firstShop[0].key)
  }

  const getShops = async () => {
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}accounts` }, { method: "GET", token: true })
    if (req.status == true) {
      setListShops(req.data)
      Ctx.setAccountsList(req.data)
      return req.data
    }
    else if (req.status == false && req.message == 'Unauthenticated') {
      navigate('/');
      localStorage.clear()
      setDisplayPage(false)
      toast("Your Token is Expired, Try Login", { type: "info" })
    }
  }

  async function fliterByName(e: any) {
    setFilterName(e.target.value)
  }

  async function changeShop(shopId: any, isChange?: boolean) {
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}accounts/${shopId}` }, { method: "GET", token: true })
    if (req.status == true) {
      localStorage.setItem('slug', req.data.slug)
      localStorage.setItem('username', req.data.name)
      localStorage.setItem('logoSrc', req.data.logo)
      localStorage.setItem('role', JSON.stringify(req.data.role))
      localStorage.setItem('shopId', req.data.id)
      setNavData(NavData)
      Ctx.setShopLogo(req.data.logo)
      if (req.data.status == 2) {
        if (checkPermission(SettingPermissions.view)) {
          navigate('/panel/setting')
        }
      } else isChange && navigate('/panel/dashboard')
      if (req.data.is_brand) {
        localStorage.setItem("isBrand", "true")
        document.documentElement.style.setProperty('--glasses--head--', "#4de58a")
        setDisplayPage(false)
      } else {
        localStorage.setItem("isBrand", "false")
        document.documentElement.style.setProperty('--glasses--head--', "#4AAAC5")
        setDisplayPage(false)
      }
      // setTimeout(()=>{ window.location.reload() }, 250)
    }
  }



  return (
    <>
      {displayPage && <LoadingPage />}

      <div id="bg-nav" className={` left-0 bottom-0 top-0  bg-[#00000073]  fixed z-40 ${props.displayNavMobile ? 'opacity-100 visible right-0 w-full min-h-screen' : 'opacity-0 invisible right-full w-0 h-0'}`}></div>
      <div className={` min-h-screen  flex justify-center px-3 pt-[20px] transition-all z-50 fixed md:relative left-[-300px]  md:left-0 ${props.displayNav ? 'w-[200px]' : 'w-[90px]'}  `} style={{ left: props.displayNavMobile && '0' }}>
        <div id="nav" className=" w-full  min-h-[calc(100vh-2rem)] h-fit mb-2 bg-glasses-head-50 rounded-2xl sticky top-4 flex flex-col justify-between py-4 px-1">
          <div className=" w-full flex flex-col">
            <div className=" w-full flex justify-center items-center flex-col mb-[30px] relative">
              <Link to={"/panel/setting"} className='  rounded-full p-1 absolute right-0 bg-slate-50 hover:bg-blue-100 top-0 w-full max-w-[22px]'>
                <svg x="0px" y="0px" viewBox="0 0 469.331 469.331" className={` w-full cursor-pointer fill-gray-600 transition-all${props.displayNav ? 'rotate-180' : 'rotate-0'}  `}>
                  <use xlinkHref="/assets/svg/edit.svg#edit" />
                </svg>
              </Link>
              <Link to={"/panel/setting"} className=" w-[80%] mb-[10px] rounded-full overflow-hidden">
                <img src={Ctx.shopLogo ? Ctx.shopLogo : ""} alt="logo" />
              </Link>
              {/* <div onClick={() => { props.setDisplayNav(!props.displayNav) }} className=" w-[20px] h-[20px] rounded-full bg-[#fff] hidden md:flex justify-center items-center cursor-pointer mt-2">
                <svg x="0px" y="0px" viewBox="0 0 24 24" className={` w-5 h-5  fill-[#C3B6BE] transition-all ${props.displayNav ? 'rotate-180' : 'rotate-0'}  `}>
                  <use xlinkHref="/assets/svg/arrow-right.svg#arrow-right" />
                </svg>
              </div> */}
            </div>
            <div onClick={() => { setIsChooseShop(!isChooseShop) }} className=' w-full flex bg-gray-300/50 cursor-pointer text-sm justify-center items-center px-1 py-1 rounded-lg mb-0 '>
              <div className={` w-full flex items-center px-1 cursor-pointer group ${props.displayNav ? 'justify-start' : 'justify-center'}`}>
                <svg x="0px" y="0px" viewBox="0 0 490.1 490.1" className={` w-5 h-5  fill-[#fff] group-hover:fill-[#5a5a5a] transition-all mr-1 `}>
                  <use xlinkHref={"/assets/svg/user.svg#user"} />
                </svg>
                {props.displayNav &&
                  <span className=" ml-1 w-[100px] overflow-hidden text-[#fff] text-xs transition-all group-hover:text-[#5a5a5a]">{localStorage.getItem("username")}</span>
                }
              </div>
              <svg x="0px" y="0px" viewBox="0 0 24 24" className={` w-10 h-5  fill-[#fff] group-hover:fill-[#5a5a5a] transition-all ${isChooseShop ? "rotate-[90deg]" : "rotate-[270deg]"} `}>
                <use xlinkHref={"/assets/svg/arrow-left.svg#arrow-left"} />
              </svg>
            </div>

            <div id="shopsListDiv" className={`w-full flex bg-gray-100/70 text-sm items-center px-1 py-1 rounded-lg mt-1 ${isChooseShop ? " opacity-100 visible h-[35px] mb-1" : " opacity-0 invisible h-0 mb-0 py-0"} duration-150 transition-all`}>
              <input value={filterName} id='searchInputProduct' className='bg-gray-200 w-[calc(100%-28px)] px-4 rounded-tl-lg rounded-bl-lg h-7' placeholder='Search Name' type={"text"}
                onChange={fliterByName} />
              <svg x="0px" y="0px" viewBox="0 0 53.627 53.627" className={` w-7 fill-gray-500 h-7 bg-gray-200 p-1 rounded-tr-lg rounded-br-lg transition-all group-hover:fill-glasses-head-50 `}>
                <use xlinkHref="/assets/svg/search.svg#search" />
              </svg>
            </div>

            <div id="shopsListDiv" className={`w-full flex flex-col bg-gray-100/70 text-sm items-center px-4 py-1 rounded-lg pt-2 overflow-auto ${isChooseShop ? " opacity-100 visible pb-16 h-[200px] mb-2" : " opacity-0 invisible h-0 mb-0 py-0 "} duration-150 transition-all`}>
              {checkisBrand() ?
                <>
                  {checkPermission("account.add") ?
                    <button onClick={() => { navigate('/panel/add-brand'); }} className={` top-0 sticky  w-full justify-center items-center px-2  bg-emerald-500 text-white rounded-lg max-w-[150px] text-center cursor-pointer py-2 `}>
                      + Add Brand
                    </button>
                    : ""
                  }
                </>
                :
                <button onClick={() => { navigate('/panel/add-brand'); }} className={` top-0 sticky  w-full justify-center items-center px-2  bg-emerald-500 text-white rounded-lg max-w-[150px] text-center cursor-pointer py-2 `}>
                  + Add shop
                </button>
              }
              {listShops.length !== 0 && listShops.filter((item) => {
                if (item.value.toLowerCase().search(filterName) !== -1) return item
              }).map((item, index) =>
                <>
                  <div onClick={() => {
                    if (localStorage.getItem("shopId") == item.key) return
                    changeShop(item.key, true)
                  }} key={item.key} className=' w-full flex justify-center items-center my-1 cursor-pointer hover:text-blue-600'>
                    {item.value}
                  </div>
                </>
              )}
            </div>

            {localStorage.getItem("role") != null && localStorage.getItem("role") != undefined && (Boolean(localStorage.getItem("role")) && navData.length !== 0) && navData.map((item: any, index: any) =>
              <>
                {checkPermission(item.rolePermission) && checkisBrand() && (item.access === "all" || item.access === "brand") &&
                  <div key={index} className='w-full flex flex-col'>
                    <Link key={index} to={item.isSubmenu == true ? item.submenu?.[0].path : item.path}>
                      <div className={` w-full h-[40px]  flex items-center  px-2 cursor-pointer group ${props.displayNav ? 'justify-start' : 'justify-center'}`}>
                        <svg x="0px" y="0px" viewBox={item.svgView} className={` w-5 h-5  fill-[#fff] group-hover:fill-[#e0e0e0] transition-all  `}>
                          <use xlinkHref={item.svgLink} />
                        </svg>
                        {props.displayNav &&
                          <span className=" ml-2 text-[#fff] text-sm transition-all group-hover:text-[#e0e0e0]">{item.name}</span>
                        }
                      </div>
                    </Link>
                    {item.isSubmenu == true && Ctx.activeItemNav.menu == item.slug &&
                      <div className=' w-full flex flex-col' >
                        {item.submenu.map((value: any, key: any) =>
                          <Link key={key} to={value.path}>
                            <div className='w-full h-[35px] flex items-center'>
                              <li className='ml-8 text-white'>{value.name}</li>
                            </div>
                          </Link>
                        )}
                      </div>
                    }
                  </div>
                }
                {checkPermission(item.rolePermission) && !checkisBrand() && (item.access === "all" || item.access === "shop") &&
                  <div key={index} className='w-full flex flex-col'>
                    <Link key={index} to={item.isSubmenu ? item.submenu?.[0].path : item.path}>
                      <div className={` w-full h-[40px]  flex items-center  px-2 cursor-pointer group ${props.displayNav ? 'justify-start' : 'justify-center'}`}>
                        <svg x="0px" y="0px" viewBox={item.svgView} className={` w-5 h-5  fill-[#fff] group-hover:fill-[#e0e0e0] transition-all  `}>
                          <use xlinkHref={item.svgLink} />
                        </svg>
                        {props.displayNav &&
                          <span className=" ml-2 text-[#fff] text-sm transition-all group-hover:text-[#e0e0e0]">{item.name}</span>
                        }
                      </div>
                    </Link>
                    {item.isSubmenu && Ctx.activeItemNav.menu == item.slug &&
                      <div className=' w-full flex flex-col' >
                        {item.submenu.map((value: any, key: any) =>
                          <Link key={key} to={value.path}>
                            <div className='w-full h-[35px] flex items-center'>
                              <li className='ml-8 text-white'>{value.name}</li>
                            </div>
                          </Link>
                        )}
                      </div>
                    }
                  </div>
                }

              </>
            )}
          </div>

          <div className=" w-full flex justify-center mt-[20px]">
            <svg onClick={logOut} x="0px" y="0px" viewBox="0 0 24 24" className={` w-5 h-5  fill-[#fff] hover:fill-[#e0e0e0] cursor-pointer  transition-all  `}>
              <use xlinkHref="/assets/svg/poweroff.svg#poweroff" />
            </svg>
          </div>

        </div>
      </div>
    </>

  );
}

export default Nav;
