import React, { useState, useContext, useEffect } from 'react';
import { ContextState } from '../../widget/context/ContextMain'
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
import LoadingPage from '../../widget/Loading/LoadingPage';
import { customApi, baseUrl, DownloadInvoiceApi } from '../../widget/apis';
import ModalDetailOrders from './MyOrderModal/ModalDetailOrders';
import ModalTrackOrders from './MyOrderModal/ModalTrackOrders';
import ModalCommentOrders from './MyOrderModal/ModalCommentOrders';
import { checkPermission } from '../../widget/layout/Nav';
import { PricePermissions } from '../../widget/rolePermissions';


function MyOrder() {
  const Ctx = useContext(ContextState);
  const [displayPage, setDisplayPage] = useState<any>(false)
  const [visibleDetail, setVisibleDetail] = useState<boolean>(false)
  const [orderDetails, setOrderDetails] = useState<any>(null)
  const [currentPage, setCurrentPage] = useState(1);
  const [listData, setListData] = useState<any[]>([]);
  const [lastPage, setLastPage] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState<any>(null)
  const [visibleComment, setVisibleComment] = useState<boolean>(false)
  const [visibleTrack, setVisibleTrack] = useState<boolean>(false)


  const changePage = (status: any) => {
    if (status) {
      if (lastPage >= currentPage + 1) {
        getData(currentPage + 1)
      }
    }
    else {
      if (currentPage > 1) {
        getData(currentPage - 1)
      }
    }
  }

  const changeStatus = async () => {
    setDisplayPage(true)
    const postData = JSON.stringify({
      'status': -1,
    })
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/orders/${orderDetails.data.id}/change_status` }, { method: "POST", token: true, body: postData })
    setDisplayPage(false)
    if (req.status) {
      toast(req.message, { type: "success" })
      getDetail(orderDetails.data.id, true)
      // setVisibleDetail(false)
    }
    else {
      toast(req.message, { type: "error" })
    }
  }

  const downloadInvoiceApi = async (id: number) => {
    setDisplayPage(true)
    try {
      const res: any = await DownloadInvoiceApi(id)
      var el = document.createElement("a");
      el.setAttribute("href", res);
      el.setAttribute("download", 'invoice');
      el.setAttribute("target", '_blank');
      document.body.appendChild(el);
      el.click();
      el.remove();
      setDisplayPage(false)
    } catch {
      setDisplayPage(false)
    }
  }

  const getDetail = async (item_id: any, isChangeStatus: boolean) => {
    const get = async () => {
      setDisplayPage(true)
      var res = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/my_orders/${item_id}` }, { method: 'GET', token: true })
      if (res.status) {
        var arr = [...res.data.products]
        for (var i in arr) {
          if (arr[i].type.toLowerCase().includes("sunglass")) arr[i].type = "sunglass"
          if (arr[i].type.toLowerCase().includes("eyeglass")) arr[i].type = "eyeglass"
          if (arr[i].type.toLowerCase().includes("contactlens")) arr[i].type = "contact_lens"
        }
        res.product = arr
        res = { ...res, itemId: item_id }
        setOrderDetails(res)
      } else {
        setDisplayPage(false)
        toast("not found", { type: "info" })
      }
      setVisibleDetail(true)
      setDisplayPage(false)
    }
    if (displayPage) return
    if (!isChangeStatus) {
      if (orderDetails?.data?.id !== item_id) {
        get()
      } else {
        setVisibleDetail(true)
      }
    } else {
      get()
    }
  }

  const getData = async (current: any) => {
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/my_orders?page=${current}` },
      { method: "GET", token: true })
    setDisplayPage(false)
    if (req.status) {
      var arr = [...req.data.data]
      for (var i in arr) {
        for (var y in arr[i].products) {
          if (arr[i].products[y].type.toLowerCase().includes("sunglass")) arr[i].products[y].type = "sunglass"
          if (arr[i].products[y].type.toLowerCase().includes("eyeglass")) arr[i].products[y].type = "eyeglass"
          if (arr[i].products[y].type.toLowerCase().includes("contactlens")) arr[i].products[y].type = "contact_lens"
        }
        arr[i]["isOpened"] = false
      }
      setListData(arr)
      setCurrentPage(req.data.meta.current_page)
      setLastPage(req.data.meta.last_page)
    }
  }

  useEffect(() => {
    Ctx.setHeadTitle("My Purchases - pending")
    Ctx.setActiveItemNav({ 'menu': 'my_orders', 'subMenu': 1 })
    getData(currentPage)
  }, [])

  function collapseSection(item: any, type: "close" | "open") {
    var lastArr: any = [...listData]
    let targetElIn = lastArr.findIndex((e: any) => e.id == item.id)
    if (type === "close") {
      lastArr[targetElIn].isOpened = false
    }
    if (type === "open") {
      lastArr[targetElIn].isOpened = true
    }
    setListData([...lastArr])
  }


  return (
    <>
      {displayPage && <LoadingPage />}
      <div className=" w-full  flex flex-col ">

        <div className=" w-full  h-fit mb-2 p-4 flex flex-col rounded  overflow-hidden  mx-auto">
          <div className=" w-full flex flex-wrap mt-4">
            {listData.map((item: any, index: any) =>
              <div key={index} className={` w-full mb-6 py-3 ${item.isOpened && " pb-14"} rounded border-b flex items-start flex-wrap transition-all duration-700 ${item.isOpened ? " max-h-[10000px] " : " max-h-[210px] "} overflow-y-hidden overflow-x-hidden relative `}>
                {item.products.length > 2 &&
                  <div className=' absolute bottom-0 left-0 right-0 mx-auto w-full flex justify-center h-[50px] items-center bg-slate-50/60 drop-shadow-xl  '>
                    <span onClick={() => {
                      item.isOpened ? collapseSection(item, "close") : collapseSection(item, "open")
                    }} className=' cursor-pointer text-xs rounded-xl p-2 bg-blue-200/40 text-gray-700'><img className={` w-4 ${item.isOpened ? " rotate-[90deg]" : " rotate-[-90deg]"} `} src='/assets/svg/arrow-left.svg' />
                    </span>
                  </div>
                }
                <div className=" w-full md:w-[100px] flex flex-row md:flex-col items-center  overflow-hidden md:mb-0 mb-5">
                  <img src={item.shop.logo} alt="logo" className=" w-12 h-12  rounded-full" />
                  <span className=" text-gray-800 text-xs mt-0 md:mt-4 ml-[15px] md:ml-0">{item.shop.name}</span>
                </div>
                <div className=" w-full md:w-[calc(100%-300px)]  flex flex-col px-3">
                  {item.products.map((itemIn: any, index: number) =>
                    <div key={index} className=" w-full flex mb-2 justify-around items-center h-[70px]">
                      <img src={itemIn?.image ? itemIn.image : "/assets/media/b5.jpg"} alt="logo" className="sm:h-[70px] h-[40px] mx-2" />
                      <div className=" flex flex-col items-center sm:w-[100px] justify-between sm:text-base text-xs  mx-2">
                        {itemIn.type === "contact_lens" ?
                          <div className=' flex w-full flex-col justify-center items-center'>
                            <div className=' w-full flex justify-between px-2 items-center bg-gray-200 mb-2 rounded'>
                              <span className=" text-sm  mr-6"> SPH</span>
                              <span className=' text-sm '>{itemIn.sph}</span>
                            </div>
                            <div className=' w-full flex justify-between px-2 items-center bg-gray-200 rounded'>
                              <span className=" text-sm  mr-6"> Count</span>
                              <span className=' text-sm '>{itemIn.count}</span>
                            </div>
                          </div>
                          :
                          <div className=' w-full flex items-center justify-around'>
                            <img className=" w-[20px] h-[20px] rounded-full bg-red-100" src={itemIn?.color?.image ? itemIn?.color?.image : "/assets/media/gucci.png"} />
                            <span className=" text-[11px] px-1"> X</span>
                            <span>{itemIn.count}</span>
                          </div>
                        }
                      </div>
                      <div className=" flex flex-col mx-2">
                        {checkPermission(PricePermissions) &&
                          <>
                            {itemIn.discount == 0 ?
                              <span className="text-sm">AED {itemIn.price}</span>
                              :
                              <span className="text-sm">AED {itemIn.price - (itemIn.price * itemIn.discount / 100)}</span>
                            }
                          </>
                        }
                      </div>
                    </div>
                  )}
                </div>
                <div className=" w-full md:w-[200px] flex flex-row md:flex-col justify-center items-end px-2 pl-4 rounded">

                  <div className=" flex items-center mb-0 md:mb-[10px] order-2 md:order-1 mt-[30px]">
                    {/* <div onClick={() => {
                      item?.tracking && setSelectedOrder(item?.tracking)
                      item?.tracking && setVisibleTrack(true)
                    }} className=" w-7 h-7 rounded-full bg-gr-100 flex justify-center items-center cursor-pointer mr-1 relative group">
                      <div className="bg-gr-100 w-[60px] h-[20px] absolute text-gray-500 flex justify-center items-center top-[-25px] text-sm rounded opacity-0 invisible group-hover:top-[-30px] group-hover:opacity-100 group-hover:visible transition-all ">Track</div>
                      <img src="/assets/svg/car.png" className="w-4 h-4" />
                    </div> */}
                    <div onClick={() => downloadInvoiceApi(item.id)} className=" w-7 h-7 rounded-full bg-gr-100 flex justify-center items-center cursor-pointer mr-1 relative group">
                      <div className="bg-gr-100 w-[60px] h-[20px] absolute text-gray-500 flex justify-center items-center top-[-25px] text-sm rounded opacity-0 invisible group-hover:top-[-30px] group-hover:opacity-100 group-hover:visible transition-all ">invoice</div>
                      <img src="/assets/svg/invoice.svg" className="h-4" />
                    </div>
                    <div onClick={() => getDetail(item.id, false)} className=" w-7 h-7 rounded-full bg-gr-100 flex justify-center items-center cursor-pointer mr-1 relative group">
                      <div className="bg-gr-100 w-[60px] h-[20px] absolute text-gray-500 flex justify-center items-center top-[-25px] text-sm rounded opacity-0 invisible group-hover:top-[-30px] group-hover:opacity-100 group-hover:visible transition-all ">View</div>
                      <img src="/assets/svg/eye.svg" className="h-4" />
                    </div>
                    {checkPermission(PricePermissions) &&
                      <Link to={`/panel/invoice/${item?.id}`}>
                        <a className=" w-7 h-7 rounded-full bg-gr-100 flex justify-center items-center cursor-pointer mr-1 relative group">
                          <div className="bg-gr-100 w-[60px] h-[20px] absolute text-gray-500 flex justify-center items-center top-[-25px] text-sm rounded opacity-0 invisible group-hover:top-[-30px] group-hover:opacity-100 group-hover:visible transition-all ">invoice</div>
                          <img src="/assets/svg/receipt.png" className="h-4" />
                        </a>
                      </Link>
                    }
                  </div>

                  {checkPermission(PricePermissions) &&
                    <div className=" w-full flex flex-col order-1 md:order-2">
                      <div className="w-full flex  items-center text-xs">
                        <span>Shipping:</span>
                        <span className="ml-[20px]">AED {item.shipping_price}</span>
                      </div>
                      <div className=" flex flex-col mt-[15px]">
                        <span>subtotal :</span>
                        <span className=" font-bold">AED {item.total_payment}</span>
                      </div>
                    </div>
                  }
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="table-counter">
        <div className="table-counter-main">
          {currentPage != 1 &&
            <div className="table-counter-main-item" onClick={() => changePage(false)} >
              <svg x="0px" y="0px" viewBox="0 0 24 24" className={` w-4 h-4 fill-glasses-head-50 transition-all  `}>
                <use xlinkHref="/assets/svg/arrow-left.svg#arrow-left" />
              </svg>
            </div>
          }
          <div className="table-counter-main-item">{currentPage} </div>
          {lastPage >= currentPage + 1 &&
            <div className="table-counter-main-item" onClick={() => changePage(true)} >
              <svg x="0px" y="0px" viewBox="0 0 24 24" className={` w-4 h-4 fill-glasses-head-50 transition-all  `}>
                <use xlinkHref="/assets/svg/arrow-right.svg#arrow-right" />
              </svg>
            </div>
          }
        </div>
      </div>

      <ModalDetailOrders
        setDisplayPage={setDisplayPage}
        visible={visibleDetail}
        setVisible={setVisibleDetail}
        orderDetails={orderDetails}
        changeStatus={changeStatus}
        updateData={() => getData(currentPage)}
      />

      <ModalTrackOrders
        visible={visibleTrack}
        setVisible={setVisibleTrack}
        selecedOrder={selectedOrder}
      />

      <ModalCommentOrders
        visible={visibleComment}
        setVisible={setVisibleComment}
        selectedOrder={selectedOrder}
      />

    </>
  );
}

export default MyOrder;
