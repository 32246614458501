
import React, { useState, useEffect, useContext } from 'react';
import { ContextState } from '../../../widget/context/ContextMain'
import { baseUrl, customApi } from '../../../widget/apis';
import LoadingPage from '../../../widget/Loading/LoadingPage';
import TablePage from '../../../widget/Table/TablePage';
import CModal from '../../../widget/CModal/CModal';
import { toast } from 'react-toastify';
import { checkPermission } from '../../../widget/layout/Nav';
import { OperatorsPermissions } from '../../../widget/rolePermissions';

interface IRolesOperatorsProps {

}

const AddOperatorsExist: React.FunctionComponent<any> = ({displayPage ,setDisplayPage, setIsAddUserModal, getData, rolesData, selectPermission, selectedRole }) => {


    const addUser = async (e: any) => {
        e.preventDefault()
        // if (checkImageRequired() == false) return
        if (displayPage) return
        if (!selectedRole) return toast("choose one role", { type: "warning" })
        setDisplayPage(true)
        let formData = new FormData()
        formData.append("email", e.target[0].value);
        formData.append("role_id", selectedRole.id);
        formData.append("type", 'existing');

        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/users` }, { method: "POST", body: formData, formData: true, token: true })
        if (req.status) {
            toast(req.message, { type: "success" })
            setDisplayPage(false)
            const form: any = document.getElementById("addUserForm")
            getData()
            form?.reset()
            setIsAddUserModal(false)
        } else {
            setDisplayPage(false)
            toast(req.message, { type: "warning" })
        }
    }

    return (
        <>
            <div className=" w-full flex flex-col   ">
                <form id="addUserForm" name='AddCouponModal' onSubmit={addUser} className=' w-full flex flex-col flex-wrap justify-between items-start px-4'>

                    <div className=" flex flex-col my-5 w-full">
                        <span className=" text-glasses-text-50 text-sm">Email</span>
                        <input type="email" placeholder='Insert Your Email' className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" />
                    </div>

                    <div className=" flex flex-col my-5 w-full">
                        <span className=" text-glasses-text-50 text-sm mb-2">Select Role</span>
                        <div className={` flex flex-wrap w-full bg-gray-100 px-2 py-2 rounded-lg `}>
                            {Boolean(rolesData.length) && rolesData?.map((item: any, index: number) =>
                                <span onClick={() => { selectPermission(item) }}
                                    key={index} className={` ${item.isSelected ? "bg-blue-700 text-white " : "bg-blue-100  text-black "} 
                                          px-2 text-center cursor-pointer py-1 min-w-[70px]  text-xs mx-1 mb-2 rounded-lg shadow-md`}>
                                    {item.display_name}
                                </span>
                            )}
                        </div>
                    </div>

                    {selectedRole != "" &&
                        <div className=" flex flex-col my-5 w-full">
                            <span className=" text-glasses-text-50 text-sm mb-2">Permissions</span>
                            <div className={` flex flex-wrap w-full bg-gray-100 px-2 py-2 rounded-lg `}>
                                {selectedRole?.permissions?.map((item: any, index: number) =>
                                    <span key={index} className={` bg-blue-100  text-black px-2 text-center py-1 min-w-[70px]  text-xs mx-1 mb-2 rounded-lg shadow-md`}>
                                        {item.display_name}
                                    </span>
                                )}
                            </div>
                        </div>
                    }



                    <button className=" text-white rounded-lg bg-glasses-head-50 w-[120px] h-[40px] mt-9 font-bold ">Create </button>
                </form>
            </div>
        </>
    );
};

export default AddOperatorsExist;

