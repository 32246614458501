import React, { useState, useContext, useEffect } from 'react';
import { ContextState } from '../../../widget/context/ContextMain'
import BtnLoader from '../../../widget/Loading/BtnLoader';
import { customApi, baseUrl, ImageUrl } from '../../../widget/apis';
import LoadingPage from '../../../widget/Loading/LoadingPage';
import { toast } from "react-toastify";
import TablePage from '../../../widget/Table/TablePage';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import CModal from '../../../widget/CModal/CModal';
import { Select, Slider, Popconfirm } from 'antd';
import Brand from '../../brand';
import { checkisBrand, checkPermission } from '../../../widget/layout/Nav'
import { ProductsPermissions } from '../../../widget/rolePermissions';
import GroupFilterModal from '../group-filter';
import useForceUpdate from "../../../widget/forceUpdate";

type IFilterP = {
    name?: string,
    min_price?: any,
    max_price?: any,
    brand?: string,
    category?: any,
    status?: any,
}

function ProductList() {
    const Ctx = useContext(ContextState);
    const navigator = useNavigate()
    const { Option } = Select;
    const [searchParams, setSearchParams] = useSearchParams();
    const [displayPage, setDisplayPage] = useState<any>(false)
    const [visibleDelete, setVisibleDelete] = useState<any>(false)
    const [deleteMessage, setDeleteMessage] = useState<any>(null)
    const [currentPage, setCurrentPage] = useState(1);
    const [listData, setListData] = useState<any[]>([]);
    const [lastPage, setLastPage] = useState(0);
    const [isAllowReq, setIsAllowReq] = React.useState<boolean>(true);
    const [isFilterModal, setIsFilterModal] = React.useState<boolean>(false);
    const [isGroupFilterModal, setIsGroupFilterModal] = React.useState<boolean>(false);
    const [filterItems, setFilterItems] = React.useState<IFilterP>({});
    const [listBrand, setListBrand] = useState<any>([])
    const [listCategory, setListCategory] = useState<any>([])
    const [selectedCat, setSelectedCat] = useState<any>(null)
    const [selectedBrand, setSelectedBrand] = useState<any>(null)
    const [rangePrice, setRangePrice] = useState<any>([])
    const [isUseStatus, setISUseStatus] = useState<boolean>(false)

    const detectCategory = (inp: string): { typeName: string, currentCategory: number } => {
        if (inp === "App\\Models\\SunGlass\\SunGlass") return { typeName: "sunGlasses", currentCategory: 1 }
        if (inp === "App\\Models\\EyeGlass\\EyeGlass") return { typeName: "eyeGlasses", currentCategory: 2 }
        if (inp === "App\\Models\\ContactLens\\ContactLens") return { typeName: "contactLenses", currentCategory: 3 }
        return { typeName: "as", currentCategory: 0 }
    }

    const col = [
        {
            title: "Image",
            render: (i: any) => (
                <>
                    {i.image != null &&
                        <div className='flex flex-col items-center mt-2'>
                            <img src={i.image} alt="cover" className='h-[40px] rounded' />
                            <div className='w-full flex items-center justify-center my-[4px]'>
                                {i.colors && i.colors.map((items: any, index: any) =>
                                    <img src={items} className=' w-[10px] h-[10px] rounded-full mx-[2px]' alt={items} />
                                )}
                            </div>
                        </div>
                    }
                </>
            )
        },
        {
            title: "Name",
            render: (i: any) => (<>{i.title != null && i.title}</>)
        },
        {
            title: "Price",
            render: (i: any) => (<>{i.price != null && i.price}</>)
        },
        {
            title: "Discount",
            render: (i: any) => (<>{i.discount != null && i.discount}</>)
        },
        {
            title: "Status",
            render: (i: any) => (
                <>
                    {i.status == 2 && <span className=" text-[#16BB83] font-bold">Enabled </span>}
                    {i.status == 0 && <span className=" text-[#FF3A44] font-bold">Disabled by admin </span>}
                    {i.status == 1 && <span className=" text-[#FFA500] font-bold">Disabled </span>}

                </>
            )
        },
        {
            title: "Change Status",
            render: (i: any) => (
                <>
                    {i.status != 0 ?
                        <label key={i.id} className="table-toggle">
                            <>
                                <input type="checkbox" onChange={() => handelActive(i.id)} checked={i.status == 2} />
                                <div className="table-toggle-main" />
                            </>
                        </label>
                        :
                        <span></span>
                    }
                </>
            )
        },
        {
            title: "Edit",
            display: checkPermission(ProductsPermissions.update) ? true : false,
            render: (i: any) => (
                <>
                    <Link to={checkisBrand() ?
                        `/panel/product/edit/${detectCategory(i.type).currentCategory == 3 ? "contact-lenses/" : ""}${i.id}?page=${currentPage}&categoryId=${detectCategory(i.type).currentCategory}&categoryName=${detectCategory(i.type).typeName}`
                        :
                        `/panel/product/edit-as-shop${detectCategory(i.type).currentCategory == 3 ? "/contact-lenses" : ""}/${i.id}?page=${currentPage}&categoryId=${detectCategory(i.type).currentCategory}&categoryName=${detectCategory(i.type).typeName}`}
                    >
                        <button
                            className=" h-[35px] px-3 text-white rounded-xl bg-glasses-head-50 flex items-center justify-center">
                            <svg x="0px" y="0px" viewBox="0 0 469.331 469.331"
                                className={` w-3 h-3 fill-white transition-all mr-2  `}>
                                <use xlinkHref="/assets/svg/edit.svg#edit" />
                            </svg>
                            <span>Edit</span>
                        </button>
                    </Link>
                </>
            )
        },
        {
            title: "View",
            display: checkisBrand() ? false : true,
            render: (i: any) => (
                <>
                    {i.id != null && i.status == 2 &&
                        <a href={`https://optics4less.com${i.uri}`} target='_blank'>
                            <div
                                className=" h-[35px] w-[35px] px-1 border border-glasses-head-50 flex items-center justify-center rounded">
                                <svg x="0px" y="0px" viewBox="0 0 512 512"
                                    className={` w-4 h-4 fill-glasses-head-50 transition-all   `}>
                                    <use xlinkHref="/assets/svg/eye.svg#eye" />
                                </svg>
                            </div>
                        </a>
                    }
                </>
            )
        },
        {
            title: "Delete",
            display: checkisBrand() ? true : false,
            render: (i: any) => (
                <>
                    <Popconfirm
                        title="Are you sure to delete this product?"
                        onConfirm={() => handelDelete(i.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <div
                            className=" h-[35px] w-[35px] px-1 border border-[tomato] flex items-center justify-center rounded cursor-pointer">
                            <svg x="0px" y="0px" viewBox="0 0 512 512"
                                className={` w-4 h-4 fill-red-500 transition-all   `}>
                                <use xlinkHref="/assets/svg/trash.svg#trash" />
                            </svg>
                        </div>
                    </Popconfirm>
                </>
            )
        }
    ]

    const handelDelete = async (id: number) => {
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/products/${id}` }, {
            method: "DELETE",
            token: true
        })
        setDisplayPage(false)
        if (req.status) {
            getData(currentPage, null, filterItems.name, rangePrice[0], rangePrice[1], filterItems.category, filterItems.brand, filterItems.status)
            toast(req.message, { type: "success" })
        } else {
            setDeleteMessage(req.message)
            setVisibleDelete(true)
        }
    }

    const handelActive = async (id: number) => {
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/products/${id}/change_status` }, {
            method: "GET",
            token: true
        })
        setDisplayPage(false)
        if (req.status) {
            getData(currentPage, null, filterItems.name, rangePrice[0], rangePrice[1], filterItems.category, filterItems.brand, filterItems.status)
            toast(req.message, { type: "success" })
        } else {
            toast(req.message, { type: "error" })
        }
    }

    const changePage = (status: any) => {        
        if (status) {
            if (lastPage >= currentPage + 1) {
                getData(currentPage + 1, null, filterItems.name, rangePrice[0], rangePrice[1], filterItems.category, filterItems.brand, filterItems.status)
            }
        } else {
            if (currentPage > 1) {
                getData(currentPage - 1, null, filterItems.name, rangePrice[0], rangePrice[1], filterItems.category, filterItems.brand, filterItems.status)
            }
        }
    }

    const getBrands = async () => {
        const req = await customApi({ url: `${baseUrl}brands/dropdown` }, { method: "GET", token: true })
        if (req.status == true) {
            setListBrand(req.data)
        }
    }

    const getCategory = async () => {
        const req = await customApi({ url: `${baseUrl}categories/dropdown` }, { method: "GET", token: true })
        if (req.status == true) {
            setListCategory(req.data)
        }
    }

    const getData = async (current: any, searchKey?: any, name?: any, min_price?: any, max_price?: any, category?: any, brand?: any, status?: any) => {
        setDisplayPage(true)
        const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/products?page=${current}${searchKey ? `&name=${searchKey}` : ""}${name ? `&name=${name}` : ""}${min_price ? `&min_price=${min_price}` : ""}${max_price ? `&max_price=${max_price}` : ""}${category ? `&category=${JSON.parse(category).id}` : ""}${!checkisBrand() ? `${brand ? `&brand=["${brand}"]` : ""}` : " "}${isUseStatus ? `${status ? `&status=${status}` : ""}` : ""}` }, {
            method: "GET",
            token: true
        })
        setDisplayPage(false)
        if (req.status == true) {
            setListData([...req.data.data])
            setCurrentPage(req.data.meta.current_page)
            setLastPage(req.data.meta.last_page)
            setIsFilterModal(false)
            // setSelectedBrand(null)
            // setSelectedCat(null)
            if (searchParams.get('page')) {
                searchParams.delete('page');
                setSearchParams(searchParams);
            }
        }
    }

    useEffect(() => {
        Ctx.setHeadTitle("List Product")
        Ctx.setActiveItemNav({ 'menu': 'products', 'subMenu': 0 })
        if (searchParams.get('page') != null) {
            getData(searchParams.get('page'))
        } else {
            getData(currentPage)
        }
        getBrands()
        getCategory()
    }, [])

    async function searchWithDelay(e: any) {
        setFilterItems((prev) => ({ ...prev, name: e.target.value }))
        if (isAllowReq) {
            const Input: any = document.getElementById(`searchInputProductList`)
            setIsAllowReq(false)
            setCurrentPage(1)
            setTimeout(async () => {
                // if (!stockInput.value) return setIsAllowReq(true)
                getData(1, null, Input.value, rangePrice[0], rangePrice[1], filterItems.category, filterItems.brand, filterItems.status)
                setIsAllowReq(true)
            }, 1500)
        }
    }

    function filterInModal(e: any) {
        e.preventDefault()        
        const filterItemBody: any = {
            name: e.target[0].value,
            min_price: rangePrice[0],
            max_price: rangePrice[1],
            category: selectedCat,
            brand: selectedBrand,
        }
        if (isUseStatus) {
            filterItemBody["status"] = +`${e.target[`${checkisBrand() ? 2 : 3}`].checked ? 2 : 1}`
        }
        setFilterItems({ ...filterItemBody })
        getData(currentPage, null, e.target[0].value, rangePrice[0], rangePrice[1], selectedCat, !checkisBrand() && selectedBrand, filterItemBody.status)
        const form: any = document.getElementById("filterProductForm")
        form?.reset()
    }

    function filterCancel({
        name,
        min_price,
        max_price,
        category,
        brand,
        status
    }: { name?: any, min_price?: any, max_price?: any, max_min?: any, category?: any, brand?: any, status?: any }) {
        if (name) {
            setFilterItems((prev) => ({ ...prev, name: "" }))
            getData(currentPage, null, null, filterItems.min_price, filterItems.max_price, filterItems.category, filterItems.brand, filterItems.status)
        }
        if (min_price) {
            setFilterItems((prev) => ({ ...prev, min_price: "" }))
            getData(currentPage, null, filterItems.name, null, filterItems.max_price, filterItems.category, filterItems.brand, filterItems.status)
        }
        if (max_price) {
            setFilterItems((prev) => ({ ...prev, max_price: "" }))
            getData(currentPage, null, filterItems.name, filterItems.min_price, null, filterItems.category, filterItems.brand, filterItems.status)
        }
        if (category) {
            setFilterItems((prev) => ({ ...prev, category: "" }))
            getData(currentPage, null, filterItems.name, filterItems.min_price, filterItems.max_price, null, filterItems.brand, filterItems.status)
        }
        if (brand) {
            setFilterItems((prev) => ({ ...prev, brand: "" }))
            getData(currentPage, null, filterItems.name, filterItems.min_price, filterItems.max_price, filterItems.category, null, filterItems.status)
        }
        if (status) {
            setFilterItems((prev) => ({ ...prev, status: null }))
            getData(currentPage, null, filterItems.name, filterItems.min_price, filterItems.max_price, filterItems.category, filterItems.brand, null)
        }
    }

    useEffect(() => {
        if (!checkPermission(ProductsPermissions.view)) {
            navigator('/panel/dashboard')
        }
    }, [])


    return (
        <>
            {displayPage && <LoadingPage />}
            <div className=" w-full  flex flex-col   ">
                {/* <h1 onClick={() => {
        }}>oliooooo</h1> */}
                {checkPermission(ProductsPermissions.add) && checkisBrand() &&
                    <div className=" w-full h-[60px] flex items-center">
                        <Link
                            to={checkisBrand() ? `/panel/product/add/select-category?page=${currentPage}` : `/panel/product/add-as-shop?page=${currentPage}`}>
                            <button className=" px-[10px] h-[40px] bg-glasses-head-50 rounded-xl text-white"> + Add New
                                Product
                            </button>
                        </Link>
                    </div>
                }
                <div className=' w-full flex flex-wrap pt-4 justify-center md:justify-between items-center mb-5'>
                    <div
                        className=' flex items-center justify-center bg-gray-200 pr-4 overflow-hidden rounded-lg group'>
                        <input value={filterItems.name} id='searchInputProductList'
                            className='bg-gray-200 px-4 rounded-tl-lg rounded-bl-lg h-12'
                            placeholder='Search by product' type={"text"}
                            onChange={searchWithDelay} />
                        <svg x="0px" y="0px" viewBox="0 0 53.627 53.627"
                            className={` w-5 fill-gray-500 h-12 bg-gray-200 rounded-tr-lg rounded-br-lg transition-all group-hover:fill-glasses-head-50 `}>
                            <use xlinkHref="/assets/svg/search.svg#search" />
                        </svg>
                    </div>
                    <div className=' flex '>
                        {filterItems.name ?
                            <div onClick={() => filterCancel({ name: true })}
                                className=' flex justify-center mr-4 items-center px-2 py-1 text-sm rounded-lg bg-indigo-200 hover:bg-indigo-300 text-gray-600 relative  group cursor-pointer'>
                                <span className='  mr-2'>name:</span>
                                <span
                                    className=' cursor-pointer absolute top-0 group-hover:top-[-20px] text-red-600 opacity-0 group-hover:opacity-100 invisible group-hover:visible duration-500 transition-all'>x</span>
                                {filterItems.name}
                            </div>
                            : ""}
                        {filterItems.min_price ?
                            <div onClick={() => filterCancel({ min_price: true })}
                                className=' flex justify-center mr-4 items-center px-2 py-1 text-sm rounded-lg bg-indigo-200 hover:bg-indigo-300 text-gray-600 relative  group cursor-pointer'>
                                <span className='  mr-2'>Min-P:</span>
                                <span
                                    className=' cursor-pointer absolute top-0 group-hover:top-[-20px] text-red-600 opacity-0 group-hover:opacity-100 invisible group-hover:visible duration-500 transition-all'>x</span>
                                {filterItems.min_price} AED
                            </div>
                            : ""}
                        {filterItems.max_price ?
                            <div onClick={() => filterCancel({ max_price: true })}
                                className=' flex justify-center mr-4 items-center px-2 py-1 text-sm rounded-lg bg-indigo-200 hover:bg-indigo-300 text-gray-600 relative  group cursor-pointer'>
                                <span className='  mr-2'>Max-P:</span>
                                <span
                                    className=' cursor-pointer absolute top-0 group-hover:top-[-20px] text-red-600 opacity-0 group-hover:opacity-100 invisible group-hover:visible duration-500 transition-all'>x</span>
                                {filterItems.max_price} AED
                            </div>
                            : ""}
                        {filterItems.brand ?
                            <div onClick={() => filterCancel({ brand: true })}
                                className=' flex justify-center mr-4 items-center px-2 py-1 text-sm rounded-lg bg-indigo-200 hover:bg-indigo-300 text-gray-600 relative  group cursor-pointer'>
                                <span className='  mr-2'>Brand:</span>
                                <span
                                    className=' cursor-pointer absolute top-0 group-hover:top-[-20px] text-red-600 opacity-0 group-hover:opacity-100 invisible group-hover:visible duration-500 transition-all'>x</span>
                                {filterItems.brand}
                            </div>
                            : ""}
                        {filterItems.category ?
                            <div onClick={() => filterCancel({ category: true })}
                                className=' flex justify-center mr-4 items-center px-2 py-1 text-sm rounded-lg bg-indigo-200 hover:bg-indigo-300 text-gray-600 relative  group cursor-pointer'>
                                <span className='  mr-2'>Cat:</span>
                                <span
                                    className=' cursor-pointer absolute top-0 group-hover:top-[-20px] text-red-600 opacity-0 group-hover:opacity-100 invisible group-hover:visible duration-500 transition-all'>x</span>
                                {JSON.parse(filterItems.category).name}
                            </div>
                            : ""}
                        {isUseStatus && filterItems.status ?
                            <div onClick={() => filterCancel({ status: true })}
                                className=' flex justify-center mr-4 items-center px-2 py-1 text-sm rounded-lg bg-indigo-200 hover:bg-indigo-300 text-gray-600 relative  group cursor-pointer'>
                                <span className='  mr-2'>Status:</span>
                                <span
                                    className=' cursor-pointer absolute top-0 group-hover:top-[-20px] text-red-600 opacity-0 group-hover:opacity-100 invisible group-hover:visible duration-500 transition-all'>x</span>
                                {filterItems.status == 2 && "active"} {filterItems.status == 1 && "deactive"}
                            </div>
                            : ""}
                        <div onClick={() => setIsFilterModal(!isFilterModal)}
                            className=' flex justify-center items-center px-4 py-2 text-sm mx-2 rounded-lg bg-glasses-head-50 cursor-pointer text-white'>
                            Filter
                        </div>
                        <div onClick={() => setIsGroupFilterModal(!isGroupFilterModal)}
                            className=' flex justify-center items-center px-4 py-2 text-sm mx-2 rounded-lg bg-glasses-head-50 cursor-pointer text-white'>
                            Group Status
                        </div>
                    </div>
                </div>

                {/* --------------------------- Table --------------------------- */}
                <TablePage columns={col} data={listData}>
                    {currentPage != 1 &&
                        <div className="table-counter-main-item" onClick={() => changePage(false)}>
                            <svg x="0px" y="0px" viewBox="0 0 24 24"
                                className={` w-4 h-4 fill-glasses-head-50 transition-all  `}>
                                <use xlinkHref="/assets/svg/arrow-left.svg#arrow-left" />
                            </svg>
                        </div>
                    }
                    {new Array(lastPage).fill("any").map((item: any, index: number) =>
                        <div key={index} onClick={() => getData(index + 1 , null , filterItems?.name , filterItems?.min_price , filterItems?.max_price , filterItems?.category , !checkisBrand() && filterItems?.brand , filterItems?.status )}
                            className={`table-counter-main-item ${index + 1 == currentPage && 'table-counter-main-item-active'}`}>{index + 1} </div>
                    )}
                    {lastPage >= currentPage + 1 &&
                        <div className="table-counter-main-item" onClick={() => changePage(true)}>
                            <svg x="0px" y="0px" viewBox="0 0 24 24"
                                className={` w-4 h-4 fill-glasses-head-50 transition-all  `}>
                                <use xlinkHref="/assets/svg/arrow-right.svg#arrow-right" />
                            </svg>
                        </div>
                    }
                </TablePage>
            </div>

            <CModal title='filter options' setVisible={setIsFilterModal} visible={isFilterModal}
                uId='filterProductModal'>
                <>
                    <form id="filterProductForm" name='filterMOdal' onSubmit={filterInModal}
                        className=' w-full flex flex-col flex-wrap justify-between items-start px-4'>

                        <div className=" w-10/12 flex flex-col my-5">
                            <span className=" text-glasses-text-50 text-sm">Product Name</span>
                            <input type="text"
                                className=" w-full h-[40px] rounded-xl bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" />
                        </div>

                        {!checkisBrand() &&
                            <div
                                className={` w-10/12 flex flex-col my-5 add-product-as-shop-form ${localStorage.getItem('role') == '2' && 'opacity-0 invisible w-0 h-0 overflow-hidden my-0'}`}>
                                <span className=" text-glasses-text-50 text-sm mb-1">Brand</span>
                                <Select
                                    className=' w-full rounded'
                                    showSearch
                                    allowClear
                                    placeholder="Select a brand"
                                    optionFilterProp="children"
                                    onChange={(value) => {
                                        setSelectedBrand(value)
                                    }}
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {listBrand.map((item: any, index: any) =>
                                        <Option key={index} value={item.key}>{item.value}</Option>
                                    )}
                                </Select>
                            </div>
                        }


                        <div className=" w-10/12 flex flex-col my-5 add-product-as-shop-form">
                            <span className=" text-glasses-text-50 text-sm mb-1">Category</span>
                            <Select
                                className=' w-full rounded'
                                showSearch
                                allowClear
                                placeholder="Select a category"
                                optionFilterProp="children"
                                onChange={(value) => {
                                    setSelectedCat(value)
                                }}
                                filterOption={(input, option) =>
                                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {listCategory.map((item: any, index: any) =>
                                    <Option key={index} value={JSON.stringify(item)}>{item.name}</Option>
                                )}
                            </Select>
                        </div>

                        <div className=" w-10/12 flex flex-col my-5">
                            <span className=" text-glasses-text-50 text-sm mb-16">Price (AED)</span>
                            <Slider
                                onChange={(value: any) => {
                                    setRangePrice(value)
                                }}
                                range={{ draggableTrack: true }}
                                defaultValue={[10, 1500]}
                                min={4} max={5000}
                            // tooltipVisible={isFilterModal}

                            />
                        </div>

                        <div className=" w-10/12 flex my-5 ">
                            <div onClick={() => setISUseStatus(!isUseStatus)}
                                className=" border border-glasbg-glasses-head-50 w-[15px] h-[15px] flex justify-center cursor-pointer items-center mr-2 rounded-sm">
                                {isUseStatus ? <div className=" bg-glasses-head-50 w-[10px] h-[10px] rounded-sm" /> : ""}
                            </div>
                            <span className=" text-glasses-text-50 text-sm">Use filter by status</span>
                        </div>
                        {isUseStatus &&
                            <div className=" w-10/12 flex flex-col my-5">
                                <span className=" text-glasses-text-50 text-sm">Status</span>
                                <label className="table-toggle">
                                    <>
                                        <input type="checkbox" defaultChecked />
                                        <div className="table-toggle-main" />
                                    </>
                                </label>
                            </div>
                        }

                        <button
                            className=" text-white rounded-lg bg-glasses-head-50 w-[120px] h-[40px] mt-9 font-bold ">Filter
                        </button>
                    </form>
                </>
            </CModal>

            <CModal title='Message' setVisible={setVisibleDelete} visible={visibleDelete} uId='DelMesProductModal'>
                <div className='w-full flex flex-col'>
                    {deleteMessage}
                    <div className='w-full flex items-center mt-[40px]'>
                        <button
                            className='w-[120px] h-[35px] rounded border border-glasses-head-50 text-glasses-head-50'
                            onClick={() => {
                                setVisibleDelete(false)
                            }}>Close
                        </button>
                    </div>
                </div>
            </CModal>

            <GroupFilterModal
                getData={getData}
                isGroupFilterModal={isGroupFilterModal}
                setIsGroupFilterModal={setIsGroupFilterModal}
            />

        </>
    );
}

export default ProductList;
